.container {
    .row {
      margin-left: -15px;
      margin-right: -15px;
      > .col-lg-4 {
        padding-left: 15px;
        padding-right: 15px;
      }
   
    }
    .card {
        border: none;
      border-radius: 15px;
      position: relative;
      background-color: transparent;
      // padding: 20px;
      img{
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .card.first-card{
          padding: 33.8% 0;
        @media screen and (max-width: 992px) {
          // padding: 20px;
            }

    }
  }
  .third_card{
      padding: 33.7%;
      img{
        object-fit: fill;
        border: 1px solid #e3e3e3;
        border-radius: 15px;
      }
  }
  .fourth-card{
    // height: 254px;
    padding:31.6%;
    background-color: transparent;
    // margin-top:20px;
  }
  .tricomp{
    padding: 40px 0px 0;


      @media screen and (max-width: 992px) {
    padding: 10px 10px 0;
        }
  }
.tricomp-title{
    font-size: 50px;
    font-weight: 800;
    font-family: 'Kaisei Tokumin';
    text-transform: capitalize;
    margin-bottom: 52px;
    @media screen and (max-width: 568px) {
      font-size: 25px;
      margin-bottom: 10px;
        }
  }
  .dummy-text{
    color: #1AEEB6;

    @media screen and (max-width: 568px) {
      font-size: 25px;;
        }
  }
  .tricomp-container{
    background-color: #f5f5f5;
padding: 40px 0px 0;
@media screen and (max-width: 568px) {
  padding: 40px 20px 0;
    }
  }
  .card_btn{
    background-color: white;
    color: black;
    font-size: 15px;
    font-weight: 600;
    border-radius: 10px;
    width: 50%;
    padding: 10px;
    border: #058B68;
    font-family: "Inter";
    @media screen and (max-width: 992px) {
      width: 100%;
      font-size:25px;
        }
  }
  
  h2.title_text{
    font-size: 25px;
    font-weight: 400;
    color: #ffffff;
    padding-top: 30px;
    padding-bottom: 10px;
    span{
      font-size: 85px;
    font-weight: bold;
    
    }
  }
  .text_card{
    background-color: transparent;
    font-size: 20px;
    line-height: 29px;
    font-family: "Inter";
  }
  .number{
    font-size: 85px;
    font-family: "Inter";
    font-weight: 700;
  }
  .card_bg{
    // height: 490px;
    
   img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
  }

}
.card_bg{
  height: 571px;
  @media screen and (max-width: 1400px) {
    height: 491px;
  }
  // @media screen and (max-width: 1360px) {
  //   height: 491px;
  // }
  @media screen and (max-width: 1199px) {
    height: 412px;
  }
  @media screen and (max-width: 996px) {
    height: 300px;
  }
}