@import url('https://fonts.googleapis.com/css2?family=Kaisei+Tokumin:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');


.blogdetail-container{
   padding-top: 70px!important;
    @media screen and (max-width:569px) {
        padding: 32px;
    }


}

    // .col-lg-12 {
    //     padding-left: 80px;
    // }
    h2.blogtitle {
        padding-top: 48px;
        // padding-left: 173px;
        padding-bottom: 0px;
        color: rgba(11, 51, 91, 1);
        text-transform: uppercase;
        font-family: 'Inter';
        font-size: 20px;
        font-weight: 600;
    }
    h3.blogdetail {
        padding-top: 8px;
        // padding-left: 173px;
        padding-bottom: 14px;       
        font-size: 40px;
        color: rgba(11, 51, 91, 1);
        font-family: Kaisei Tokumin;
        // width: 117%;

        @media screen and (max-width:992px) {
            font-size: 24px;
            width: 100%;
            line-height: 35px;
        }
    }
    h5.dateandtime {
        padding-top: 8px;
        padding-bottom: 55px;
        color: rgb(11, 51, 91);
        font-size: 20px;
        font-weight: 500;
        font-family: 'Inter';
        // padding-left: 173px;
    }
    .card.border-0 {
        // padding-left: 174px;
    }
    .card-body {
        padding-left: 0px;
        padding-bottom: 38px;
    }

    .blogheading{
        @media screen and (max-width:992px) {
            font-size: 25px;
        }
    }

    // img.card-img-top {
    //     padding-right: 150px;
    //     padding-bottom: 100px;
    // }
    p.card-text {
        font-family: 'Inter';
        font-size: 20px;
        font-weight: 400;
       
        @media screen and (max-width:992px) {
            font-size: 18px;
        }
    }
    ul{
        margin-left: 20px;
    }
.meeting{
    text-decoration: underline !important;
    font-weight: bold;
    color:rgb(11, 51, 91);
}
    .dermslist{
        margin-bottom: 15px;
        font-family: "Inter";
        font-size: 20px;
        font-weight: 400;

        @media screen and (max-width:992px) {
            font-size: 18px;
        }
    }
    ul.card-text {
        list-style-type: disc;
    }
    .card-footer.box {
        background-color: rgba(11, 51, 91, 1);
        border-radius: 20px;
        width: 100%;

        .card-footer-content {
            padding: 30px;
            padding-left: 40px;
        }
        p.card-text1 {
            font-size: 20px;
            color: rgba(255, 255, 255, 1);
            text-transform: capitalize;
            font-family: Kaisei Tokumin;

            @media screen and (max-width:992px) {
                font-size: 25px;
            }
        }
       
        button.btn.btn-primary {
            background-color: rgba(0, 242, 178, 1);
            border-radius: 10px;
            color: rgba(0, 0, 0, 1);
            text-transform: capitalize;
            padding-left: 33px;
            line-height: 41px;
            border: none;
            font-weight: 400;
        }
        .arrowimg{
            padding-left: 7px;
            padding-right: 5px;
        }
    }


