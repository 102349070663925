@import url('https://fonts.googleapis.com/css2?family=Kaisei+Tokumin:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');


.maindiv{
    padding: 30px;

    @media screen and (max-width:992px) {
        padding: 10px;
    }

    h2 {
        padding-left: 20px;
        text-transform: capitalize;
        color: rgba(11, 51, 91, 1);
        font-size: 45px;
        font-family: Kaisei Tokumin;
        font-weight: 800;
        @media screen and (max-width:992px) {
            font-size: 25px;
        }
    }

    .row {
        padding: 0px;
    }

    .card {
        padding: 11px;
        border-radius: 20px;
        background-color: rgba(247, 247, 247, 1);
    }

    .card-body {
        padding-left: 0px;
    }

    h5.card-title {
        color: rgba(11, 51, 91, 1);
        font-size: 19px;
        font-weight: 600;
        font-family: 'Inter';
        // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding-bottom: 6px;
        text-transform: capitalize;
    }

    p.card-text {
        font-size: 15px;
        font-family: 'Inter';
        font-weight: 400;
    }

    .date-text small.text {
        color: rgba(11, 51, 91, 1);
        font-family: 'Inter';
        font-weight: 400;
    }
    
}

