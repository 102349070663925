.job-component {
  height: calc(60vh - 70px);
    background: linear-gradient(to right, #031e39, #0B1B2B) !important;
    display: flex;
    justify-content: center;
    padding-top: 60px;
    position: relative;
    margin-top: 70px;

  @media screen and (max-width: 568px) {
    padding: 20px;
    position: relative;
    flex-wrap: wrap;
    height: calc(40vh - 60px);
  }
}

.talented {
  position: absolute;
  bottom: 0;
  top: 75%;
  border-radius: 5px;
  height: 287px;
  width: 582px;
  z-index: 1;
  @media screen and (max-width: 568px) {
    position: unset;
    height: 127px;
    width: 263px;
    padding-top: 21px;
  }
}

.talented img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.my-job-component {
  width: 100%;
  max-width: 800px;
}

.centered-div {
  text-align: center;
}

.heading {
  font-size: 60px;
  color: #ffffff;
  font-family: "Kaisei Tokumin";
  font-weight: 400;

  @media screen and (max-width: 568px) {
    font-size: 30px;
  }
}

.heading span {
  color: #00f2b2;
  font-weight: 700;
}
