.delete-account-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #f0f0f0, #d3d3d3);
  }
  
  .delete-account-box {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .delete-account-box p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .delete-button {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .delete-button:hover {
    background-color: darkred;
  }
  