.assessment-container{
    padding-top: 80px;
                    @media screen and (max-width: 992px) {
                        padding-top: 40px;  
                            }
}


.title-section{
    font-size: 55px;
    font-weight: 400;
    color: #0B335B;
    font-family: 'Kaisei Tokumin';
    margin-bottom: 32px;
    @media screen and (max-width: 568px) {
        font-size: 45px;
        margin-bottom: 10px;
    }
    @media screen and (max-width: 768px) {
        font-size: 45px;
    }
    @media screen and (max-width: 992px) {
        font-size: 45px;
    }
    span{
        font-weight: 700;
    }
}
.opinions{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Inter';

    @media screen and (max-width: 568px) {
        font-size: 15px;
    }
    @media screen and (max-width: 768px) {
        font-size: 15px;
    }
    @media screen and (max-width: 992px) {
        font-size: 15px;
    }
}

.teach-container.blogs-container {
    padding: 100px 50px;

    @media screen and (max-width: 1200px) {
        padding: 100px 20px;
    }
    @media screen and (max-width: 596px) {
        padding: 40px 20px;
    }
    a {
        text-decoration: none;
    }

    .slick-slider.slick-initialized {
        position: relative;
    }

    .slick-list {
        display: flex !important;
    }

    .slick-track {
        display: flex !important;
    }

    .slick-dots {
        margin-top: 20px;
        display: flex !important;
        justify-content: center;
        list-style: none;
        padding-left: 0;

        button {
            width: 14px;
            height: 14px;
            flex-shrink: 0;
            background-color: #dedede;
            margin: 0 6px;
            border-radius: 50%;
            font-size: 0;
            border: none;
        }

        li.slick-active button {
            background-color:#0B335B;
            ;
        }
    }
}

.assessment-container.teach-with-us img.tick-icon {
    margin-right: 26px;
}

.assessment-container.teach-with-us .benefits li {
    padding-bottom: 20px;
    padding-right: 0;

    @media screen and (max-width: 596px) {
        padding-bottom: 12px;
    }
}

.assessment-container.teach-with-us .custom_btn.enroll_btn {
    margin-top: 32px;
}

.content-section {
    padding-left: 110px;
    padding-top: 47px;

    @media screen and (max-width: 996px) {
        padding-left: 22px;
    }
}

.blog-card {
    border-radius: 10px;
    border: 1px solid #EBEBEB;
    background: #FFF;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.11);
    padding: 13px;
    margin: 10px;
}

.blog-bg-image {
    border-radius: 10px 10px 0 0;
    position: relative;
    padding: 33.1% 0;

    img {
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

    // @media screen and (max-width: 796px) {
    //     height: 270px;
    // }

    // @media screen and (max-width: 596px) {
    //     height: 207px;
    // }
}

.post-description {
    padding: 31px 7px 26px;
    height: 153px;

    @media screen and (max-width: 1200px) {
        padding: 14px;
    }

    .share-date {
        display: flex;
        justify-content: space-between;
    }

    span {
        color: #898989;
        font-size: 13px;
        font-weight: 400;
        text-decoration: none;
    }

    .share-icon-container {
        width: 18px;
        height: 18px;
        background-color: black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }

    h3 {
        color: #000;
        font-size: 15px;
        font-weight: 700;
        line-height: 23px;
        margin-top: 28px;
        text-decoration: none;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.blogs-card-container {
    overflow: hidden;

    @media screen and (max-width: 596px) {
        margin-top: 44px;
    }
}

.bolg-card-space {
    padding: 0 0px 0 4px;
}

.teach-container.blogs-container .benefits li {
    padding-right: 0;
}
.button.slick-arrow {
    display: none !important;
    background: transparent;
}
.slick-arrow{
    display: none !important;
}

.custom_btn{
    background-color: #0B335B;
    color: #ffffff;
    padding: 10px 30px;
    border-radius: 10px;
    margin-top: 20px;
    border:none;
}