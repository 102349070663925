@import url('https://fonts.googleapis.com/css2?family=Kaisei+Tokumin:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');



.bloghistory {
    padding: 30px;

    @media screen and (max-width:992px) {
        padding: 15px;
    }


    .row {
        padding: 0px;
    }

    h2.recentheading {
        color: rgba(11, 51, 91, 1);
        font-size: 45px;
        font-weight: 800;
        font-family: Kaisei Tokumin;
        margin-bottom: 52px;
        padding-left: 0;
        // padding: 5px;

        @media screen and (max-width:992px) {
            font-size: 25px;
            margin-bottom: 20px;

        }
    }

    .card-1 {
        background-image: url('../../assets/images/Rectangle\ 1358.png');
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
        height: 385px;
        border-radius: 20px;
        @media screen and (max-width:568px) {
            height: 330px;
        }
    }
    .card-1.card_two {
        background-image: url('../../assets/images/daygraph.svg');
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
        height: 385px;
        border-radius: 20px;
        @media screen and (max-width:568px) {
            height: 330px;
        }
    }
    .duck-curve{
        @media screen and (max-width:768px) {
            margin-top: 25px !important;
        }
    }

    .card-1.card_three {
        background-image: url('../../assets/images/island.png');
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
        height: 385px;
        border-radius: 20px;
        @media screen and (max-width:568px) {
            height: 330px;
        }
    }
    .card-1.card_four{
        background-image: url('../../assets/images/battery.png');
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
        height: 385px;
        border-radius: 20px;
        @media screen and (max-width:568px) {
            height: 330px;
        }
    }
    .card-1.card_five {
        background-image: url('../../assets/images/clock.png');
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
        height: 385px;
        border-radius: 20px;
        @media screen and (max-width:568px) {
            height: 330px;
        }
    }
    .card {
        position: relative;
        overflow: hidden;
        border-radius: 20px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.3);
            opacity: 0;
            transition: opacity 0.3s ease;
            z-index: 0;
        }

        &:hover::before {
            opacity: 1;
        }
    }

    .card img {
        width: 100%;
        height: auto;
        max-width: 100%;
        object-fit: cover;
    }

    .cards1 {
        font-family: 'Inter';
        color: rgba(255, 255, 255, 1);
        padding-top: 37px;
        font-weight: 700;
        font-size: 30px;
        text-transform: capitalize;

        @media screen and (max-width:768px) {
            font-size: 15px;
            line-height: 25px;
        }
    }
    @media screen and (max-width:556px) {
        .card.card-1.card_two {
            margin-top: 20px !important;
        }
    }

    .card-body {
        padding-left: 58px;
        z-index: 1;
        padding-right: 74px;
        position:relative;

        @media screen and (max-width:1190px) {
            padding-left: 57px;
        }

        @media screen and (max-width:992px) {
            padding-left: 20px;
            font-size: 20px;
            padding-right: 0px;
        }
    }
    .card-body::after {
        position: absolute;
        background: rgba(0 , 0 , 0 , .5);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        content: "";
        z-index: -1;
    }

    .second-col {
        @media screen and (max-width:992px) {
            margin-top: 15px;
        }
    }

    .card-button {
        width: 170px;
        height: 50px;
        text-transform: capitalize;
        border-radius: 10px;
        background: rgba(255, 255, 255, 1);
        color: rgba(0, 0, 0, 1);
        border: 1px solid #ffffff;
        outline: none;
        cursor: pointer;
        z-index: 1;
        position:absolute;
        bottom: 52px;

        @media screen and (max-width:992px) {
            font-size: 18px;
        }
    }

    .cardbtndiv {
        padding-top: 74px;

        @media screen and (max-width:992px) {
            padding-left: 0%;
        }

        @media screen and (max-width:768px) {
            padding-top: 141px;
        }

        @media screen and (max-width:556px) {
            padding-top: 78px;
        }
    }

    .link-button {
        display: flex;
        justify-content: end;
        padding-top: 40px;

        @media screen and (max-width:992px) {
            padding-right: 52%;
        }
    }

    button.card-button.card-btn1 {
        // left: 58%;
        // bottom: -20px;
        position: relative;

        @media screen and (max-width:568px) {
            left: 7px;
        }
    }

    .card.card-2 {
        background-image: url('../../assets/images/Solar.png');
        border-radius: 20px;
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    h5.card-title.card2 {
        font-size: 35px;
        font-family: 'Inter';
        color: rgba(255, 255, 255, 1);
        font-weight: 700;
        text-transform: capitalize;

        @media screen and (max-width:992px) {
            font-size: 25px;
        }
    }

    .card.card-3 {
        background-image: url('../../assets/images/elctrictower.png');
        border-radius: 20px;
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 0px;
    }




    @media (max-width: 768px) {
        .card-container {
            display: flex;
            flex-direction: column;

            .col-6:first-child {
                order: 1;
                padding-bottom: 20px;
            }

            .col-6:last-child {
                order: 2;
            }
        }
    }

}

.card.blog_card {
    height: 255px;
}

