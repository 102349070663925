.navbar {
    background: linear-gradient(to right, #031e39, #0B1B2B);
    color: #fff;
    padding: 10px 0;
    height: 72px;
    position: fixed;
    width: 100%;
    z-index:9;

  // .container {
  //   display: flex;
  //   align-items: center;
  // }

  .logo {
    width: 200px;
    object-fit: contain;
    max-width: 100%;
}

  .navbar-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-items {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .navbar-item {
      display: inline-block;
      margin-right: 20px;
      // font-size: 17px;
      // font-weight: 400;
      // font-family: "Inter";

      a {
        color: #fff;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #00f2b2;
        }
      }
    }
  }

  .navbar-buttons {
    button {
      margin-left: 10px;
      transition: background-color 0.3s ease;
      background-color: transparent;
      border: 1px solid #ffff;
      color: #fff;

      &:hover {
        color: black;
        background-color: #00f2b2;
      }
    }
  }

  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media screen and (max-width: 996px) {
    .content-wrapper {
      display: none;
    }
  }
}
.navbar::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background-color: #00f2b2;
  position: absolute;
  bottom: 0;
  left: 76px;
  top: 100%;
  z-index: 2;


  @media screen and (max-width: 568px) {
    left: 36px;
    width: 91%;
  }

  @media screen and (max-width: 768px) {
    left: 36px;

  }

  @media screen and (max-width: 996px) {
    left: 34px;

  }
}

.navbar::after {
  content: "";
  display: block;
  background: #00f2b2;
  position: absolute;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  bottom: -3px;
  left: 76px;
  top: auto;
  z-index: 2;
  

  @media screen and (max-width: 568px) {
    left: 36px;
    top: 97%;
   
  }

  @media screen and (max-width: 996px) {
    left: 29px;
  }
}

.mobile-sidebar {
  display: none;
}
@media screen and (max-width: 568px) {
  .mobile-sidebar {
    display: block;
  }
}

@media screen and (max-width: 568px) {
  .navbar-items {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .navbar-item {
      a {
        color: black;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #00f2b2;
        }
      }
    }
  }

  .navbar-buttons {
    button {
      margin-left: 10px;
      background: linear-gradient(to right, #0B335B, #0B1B2B);

      transition: background-color 0.3s ease;
      border: 1px solid #ffff;
      color: #fff;

      &:hover {
        background-color: #00f2b2 !important;
        color: #ffffff !important;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar-items {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .navbar-items .navbar-item a {
    color: black;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .navbar-items .navbar-item a:hover {
    color: #00f2b2;
  }

  .navbar-buttons button {
    margin-left: 10px;
    background: linear-gradient(to right, #0B335B, #0B1B2B);

    transition: background-color 0.3s ease;
    border: 1px solid #ffff;
    color: #fff;
  }

  .navbar-buttons button:hover {
    background-color: #00f2b2 !important;
  }
}

.hamburger-button {
  display: none;
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  padding: 5px;
  @media screen and (max-width: 996px) {
    display: block;
  }
}

.hamburger-button:focus {
  outline: none;
}

.canva-logo {
  max-width: 52%;
  height: auto;
}
button.btn-close {
  padding: 0px 25px!important;
}

