body {
width: 100%;
overflow-y:auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.accordion-body {
  padding: 0 !important;
  background-color: #F5F5F5;
}

.accordion-button::after{
  display: none;
}
.offcanvas.offcanvas-start.show{
  width:300px;
}

