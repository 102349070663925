.contactus {
  background: linear-gradient(to right, #0b335b, #0b1b2b);
  padding: 103px 0px;

  @media screen and (max-width: 568px) {
    padding: 20px;
    margin-top:58px;
    padding: 30px;
  }
}

.contact-form {
  // margin-top: 40px;
  // display: flex;
  // justify-content: space-between;
  // align-items: flex-start;
  padding: 0 0px;

  @media screen and (max-width: 568px) {
    margin-top: 0px;
    padding: 0 20px;
  }

  .contact-details {
    padding-right: 20px;

    h2 {
      font-size: 60px;
      font-weight: 800;
      font-family: "Kaisei Tokumin";

      margin-bottom: 32px;
      color: #ffffff;

      @media screen and (max-width: 568px) {
        font-size: 30px;
        margin-bottom: 20px;
      }
    }
    h2 span {
      color: #00f2b2;
    }

    p {
      font-size: 25px;
      font-weight: 400;
      font-family: "Inter";
      color: #ffffff;

      @media screen and (max-width: 568px) {
        font-size: 20px;
      }
    }
  }

  form {
    flex: 2;

    .form-group {
      margin-bottom: 20px;

      label {
        display: block;
        font-size: 15px;
        font-weight: 400;
        font-family: "Inter";
        color: #ffffff;
        margin-bottom: 5px;
        sup {
          color: #11e8af;
          font-size: 17px;
          top: 0;
        }
      }
      .custom-file-input {
        background-color: #ffffff;
        color: #000000;
        text-align: center;
        border: none;
        padding: 15px 20px;
        font-size: 13px;
        font-weight: 500;
        font-family: "Inter";
        border-radius: 7px;
        cursor: pointer;
        user-select: none;
      }
      .custom-file-input span {
        color: #00f2b2;
      }

      input[type="file"]::-webkit-file-upload-button {
        display: none;
      }
      input[type="text"],
      input[type="email"],
      textarea,
      input[type="file"] {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 7px;
      }

      textarea {
        height: 100px;
      }

      input[type="file"] {
        padding: 8px 0;
      }
    }

    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media screen and (max-width: 568px) {
        display: flex;
        flex-wrap: wrap;
      }

      .form-field {
        width: 48%;

        @media screen and (max-width: 568px) {
          margin-top: 10px;
          width: 100%;
        }
      }
    }

    .submit-button {
      background-color: #00f2b2;
      // position: relative;
      color: #000000;
      text-align: center;
      border: none;
      padding: 12px 40px;
      font-size: 15px;
      font-weight: 500;
      font-family: "Inter";
      border-radius: 7px;
      cursor: pointer;
      display: flex;
      align-items: center;
      @media screen and (max-width: 568px) {
       display: flex;
       margin: auto;;
      }


      // &:hover {
      //   background: linear-gradient(to right, #0b335b, #0b1b2b);
      //   color: #ffffff;
      // }
    }

    .button-icon {
      margin-right: 10px;
    }

    .button-text {
      flex: 1;
    }
  }

  #attachments {
    text-align: center;
  }
  .loader {
    border: 4px  linear-gradient(to right, #0b335b, #0b1b2b);
    border-top: 4px solid #000000;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-right: 8px;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.contactus.highlight{
  input.first_name{
    outline: 3px solid #00f2b2 !important;
    box-shadow: 0px 0px 6px 3px #00f2b2!important;
  }
}
