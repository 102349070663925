@import url('https://fonts.googleapis.com/css2?family=Kaisei+Tokumin:wght@400;500;700&display=swap');


.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 80px;

  // height: 100vh;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    padding: 0 20px;
    // height: 50vh;

  }

  .heading {
    font-size: 45px;
    font-family: 'Kaisei Tokumin';
    color: #0B335B;
    margin-bottom: 52px;
    text-align: left;
    font-weight: 700;

    @media screen and (max-width: 568px) {
      font-size: 30px;
      margin-bottom: 10px;

    }
  }

  .description {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: left;

    @media screen and (max-width: 568px) {
      font-size: 12px;
    }

    br {
      @media screen and (max-width: 568px) {
        display: none;
      }
    }
  }

  .derms-image {
    max-width: 100%;
    height: 100%;
    object-fit: contain;

    @media screen and (max-width: 768px) {
      margin-top: 40px;
      height: 211px;
    }
  }
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.derms_list {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(216, 216, 216, 1);
  padding-bottom: 20px;

  span {
    color: rgba(19, 183, 140, 1);
    font-weight: 700;
    line-height: 30px;
    font-size: 23px;
    font-family: 'Inter';

    @media screen and (max-width: 768px) {
      font-size: 20px;

    }
  }

  p {
    font-size: 20px;
    line-height: 25px;
    font-family: 'Inter';
    color: black;
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 0;

    @media screen and (max-width: 768px) {
      font-size: 15px;

    }
  }
}

.grap_image_container {
  height: 433px;

  @media screen and (max-width: 768px) {
    height: 220px;
    display: flex;
    justify-content: center;
  }

}

.content_derms {
  margin-top: 0px;

  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
}

.schedule-meeting {
  background: linear-gradient(to right, #f3fffc, #c9fff1);
  padding: 25px 0px;

  @media screen and (max-width: 996px) {
    padding: 25px 0px 47px;
  }

  @media screen and (max-width: 768px) {
    padding: 25px 20px 47px;
  }
a{
  background: #0b335b;
    color: #00f2b2;
    padding: 15px 20px;
    border-radius: 15px;
    box-shadow: 0px 2px 8px 0px rgb(142 140 140);
    @media screen and (max-width: 568px) {
      padding: 12px 20px;
      border-radius: 15px;
    }
}
  .schedule_btn {
    font-size: 20px;
    background: #0b335b;
    color: #00f2b2;
    font-weight: 500;
    border: 0;
  }

  .my_title {
    font-size: 50px;
    font-weight: bold;
    line-height: 55px;
    font-family: 'Kaisei Tokumin';
    color: #13b78c;

    @media screen and (max-width: 568px) {
      font-size: 30px;
      line-height: 35px;
    }

    span {
      font-size: 30px;
      color: #0B335B;

      @media screen and (max-width: 568px) {
        font-size: 20px;
      }
    }
  }
}