.job-role{
    z-index: -1;
    margin-top:270px;
    margin-bottom: 50px;


    @media screen and (max-width: 568px) {
margin-top: 40px;
margin-bottom: 50px;

    }
}

h1{
    font-size: 30px;
    font-weight: 800;
       font-family: 'Kaisei Tokumin';
       color: #0B335B;

}
h4{
    background-color: #13B78C;
    color: #ffffff;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Inter';
    margin-bottom: 28px;
    text-align: right;
    padding: 10px;

    @media screen and (max-width: 576px) {
        font-size: 14px;
        text-align: left;
    }
}
.role{
    font-size: 20px;
    font-weight: 600;
    font-family: 'Inter';
    color: #0B335B;

    @media screen and (max-width: 576px) {
        font-size: 14px;
       
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
        text-align: left;
    }
    @media screen and (max-width: 992px) {
        font-size: 14px;
        text-align: left;
    }
}


.responsibility{
    font-size: 25px;
    font-weight: 500;
    font-family: 'Inter';
    color: #0B335B;
    margin-left: 32px;
    @media screen and (max-width: 576px) {
margin-left: 10px;
}
}
.text-end{
    display: flex;
    justify-content: flex-start;
}
.apply-now{
    background-color: #13B78C;
    color: #ffffff;
    padding: 10px 24px;
    border-radius: 7px;
    border: none;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Inter';
    margin: 0px 32px 32px;
}
.opening_list_order{
padding:21px 50px;

@media screen and (max-width: 576px) {
    padding: 30px;
}
}
@media screen and (max-width: 576px) {
    .col-sm-3 {
      flex: 0 0 100%; 
      max-width: 100%; 
      margin-bottom: 10px; 
    }

}
.drop-down-icon{
    padding-right: 20px;
}

.role-description{
  font-size: 17px;
  font-weight: 400;
  font-family: 'Inter';

    @media screen and (max-width: 996px) {
      font-size: 14px;
      font-weight: 400;
    }

    @media screen and (max-width: 768px) {
        font-size: 14px;
      }
}
.accordion-button:not(.collapsed){
    background-color: #f5f5f5!important;
    box-shadow: none!important;

}
.accordion-item , .accordion-button{
    background-color: #f5f5f5!important;
    border: none!important;;
    border-radius: 20px!important;
}
.accordion-item{
    padding: 28px 42px;
    margin-bottom: 20px;
    @media screen and (max-width: 576px) {
        padding: 10px 10px;
      }
}
.accordion-button:focus{
    box-shadow: none!important;
}
.vacancies{
    width:80px;
    display: block;
    text-align: center;
    @media screen and (max-width: 996px) {
        width:56px;
      }
}
