@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.component-with-cards {
  text-align: center;
  padding: 20px 0;
  position: relative;

  @media screen and (max-width: 992px) {
    padding: 20px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 0 40px;
  }
}

.header {
  margin-bottom: 20px;
}

.Creativity-heading {
  font-size: 45px;
  font-weight: 800;
  line-height: 63px;
  font-family: "Kaisei Tokumin";
  color: #0b335b;
  margin-bottom: 52px;

  @media screen and (max-width: 568px) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
  }
}

.Creativity-heading span {
  color: #00f2b2;
}

.Creativity-paragraph {
  font-size: 20px;
  font-weight: 400;
  font-family: "Inter";

  @media screen and (max-width: 568px) {
    font-size: 14px;
  }
}

.Creativity-cards-container {
  display: flex;
  justify-content: space-between;

  z-index: -1;
}

.Creativity-card {
  border: 1px solid #afadad;
    text-align: left;
    border-radius: 15px;
    padding: 46px 0px 26px 20px;
    margin: 0 10px;
    @media screen and (max-width: 568px) {
      padding: 20px;
    }
}

.Creativity-card.right {
  border: 1px solid #afadad;
    text-align: right;
    border-radius: 15px;
    padding: 46px 20px 26px 0px;
    margin: 0 10px;

  @media screen and (max-width: 568px) {
    margin-top: 0px;
    text-align: left;
    padding: 20px;
  }
}

.Creativity-card-heading {
  font-size: 25px;
  font-weight: 600;
  color: #13b78c;
  font-family: 'Inter';
  @media screen and (max-width: 1200px) {
    font-size: 22px;
  }
}

.Creativity-card-paragraph {
  font-size: 17px;
  font-weight: 400;
  font-family: "Inter";

  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
}

.Creativity-center-image {
  top: 31%;
  left: 36.4%;
    position: absolute;
    z-index: 1;
    /* transform: translateX(-13%); */
    width: 353px;
    height: 370px;

  // @media screen and (max-width: 568px) {
  //   height: 34%;
  //   width: 60%;
  //   top: 39%;
  //   left: 27%;
  //   position: absolute;
  //   z-index: 1;
  // }
  @media screen and (max-width: 1399px) {
    left: 34.4%;
  }
  @media screen and (max-width: 1200px) {
    left: 35.9%;
    width:271px;
}
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.Creativity-card-subheading {
  margin-top: 94px;
  font-size: 25px;
  font-weight: 600;
  color: #13b78c;

  @media screen and (max-width: 1200px) {
    font-size: 22px;
    margin-top: 40px;

  }
}

.Creativity-card-subtext {
  font-size: 17px;
  font-weight: 400;
  font-family: "Inter";
  @media screen and (max-width: 1200px) {
    font-size: 14px;
  }
}