@import url('https://fonts.googleapis.com/css2?family=Kaisei+Tokumin:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');


.my-blog-component{
    display: flex;
    align-items: center;
    height: calc(60vh - 70px);
    background: linear-gradient(to right, #031e39, #0B1B2B);
    position: relative;
    z-index: 1;
    padding-top: 70px;
}

.headers {
    text-transform: capitalize;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-family: Kaisei Tokumin;
    font-size: 70px;
    margin-bottom: 0;
    // padding-left: 76px;


}

.headers span{
    color: #00F2B2;
    font-weight: 700;
}

p.blogdescription.pl-6 {
    color: rgba(255, 255, 255, 1);
    font-family: 'Inter';
    font-size: 25px;
    margin-top:40px;
    font-weight: 300;
}

@media screen and (max-width: 768px) {
    .my-blog-component{
        height: 40vh;
    }
    .headers {
        font-size: 20px;
        padding-left: 20px;
    }

    p.blogdescription.pl-6 {
        font-size: 15px;
        padding-left: 20px;
    }
}

@media screen and (max-width: 576px) {
    .my-blog-component{
        height: 40vh;
    }
    .headers {
        font-size: 35px;
        padding-left: 20px;
    }

    p.blogdescription.pl-6 {
        font-size: 15px;
        padding-left: 20px;
    }
}