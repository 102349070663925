.image-with-content {
    display: flex;
    margin-top: 80px;
    padding: 120px;
    background: linear-gradient(to right, #0B335B, #0B1B2B);

    // position:absolute;

 

    @media screen and (max-width: 568px) {
      padding:40px 20px;
      margin-top: 40px;    
          }
  }
//   .image-with-content::before {
//     content: "";
//     display: block;
//     background-image: url('../assets/images/Laptop-bg.svg'); 
//     background-size: cover; 
//     background-position: center; 
//     position: absolute;
//     height: 100px;
//     width: 100px; 
//     top: 0; 
//     left: 0; 
//     z-index: 1;
// }
  .fourth-left-column {
    flex: 1;
    padding: 57px;
  }
  
  .fourth-right-column {
    flex: 1;
    padding-left: 20px; 
    margin-top: 50px;
  }
  
  .fourth-heading {
    font-size: 45px;
    font-weight: 800;
    line-height: 63px;
    font-family: 'Kaisei Tokumin';
    color: #ffffff;
    margin-bottom: 42px;

    @media screen and (max-width: 568px) {
      font-size: 25px;
      margin-bottom: 10px;
      line-height: 35px;
      
          
          }
  }
  
  .fourth-heading span {
    color: #00F2B2;
  }
  
  .fourth-paragraph {
    font-size: 23px;
    font-weight: 400;
    line-height: 35px;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
    margin-bottom: 10px; 
    @media screen and (max-width: 568px) {
      font-size: 14px;
      line-height: 20px;
      
          
          }
  }
  
  .fourth-image {
    width: 100%;
    height: 100%;
    @media screen and (max-width: 568px) {
      width: 100%;
      
          
          }
  }
  .solar_data{
    font-weight: 700;
    color: #00F2B2;
  }
