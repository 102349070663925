a {
text-decoration: none;
color: inherit;
flex: 1;
}

.page { 
display: flex;
flex-direction: column;
}

.upper {
position: relative;
padding-top: 30px;
width: 100%;
/* background-color: #27ae60; */
background: linear-gradient(to right, #0B335B, #0B1B2B);
}

.lower {
position: relative;
padding-bottom: 5px;
width: 100%;
background-color: white;
height: 100%;
}

.upperCard {
max-width: 640px;
box-sizing: border-box;
margin: auto;
padding: 50px 10px 0;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-family: sans-serif;
color: black;
border: 1px solid #e7e7e7;
background: #fff;
border-radius: 6px 6px 0 0;
}

.lowerCard {
position: relative;
max-width: 640px;
margin: auto;
margin-top: 0%;
padding: 10px 10px 30px 10px;
box-sizing: border-box;
font-family: sans-serif;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
min-height: 100%;
}

.row__linked {
display: flex;
height: 70%;
flex-direction: row;
justify-content: space-around;
width: 100%;
border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.v-card-container {
display: flex;
align-items: center;
flex-direction: column;
padding: 15px 0 5px;
cursor: pointer;
color: #27AE60;
}

.v-card-container.border_lft-rtl {
border-left: 1px solid rgba(0, 0, 0, 0.25);
border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.icon {
font-size: 16px;
}

.text {
font-size: 10px;
padding: 10px;
}

.image {
display: flex;
justify-content: center;
align-items: center;
height: 150px;
background: #fff;
width: 150px;
border-radius: 100px;
box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.13);
}

.image img {
width: 94px;
}

.upperCard h3 {
text-transform: uppercase;
font-size: 18px;
font-weight: 700;
}

.title {
font-size: 14px;
font-weight: 500;
padding-bottom: 32px;
color: black;
text-align: center;
line-height: 22px;
}

.vcard-details {
display: flex;
flex-direction: column;
justify-content: space-between;
width: 100%;
}

.detail-container {
padding: 10px;
}

.vcard-row {
display: flex;
align-items: center;
flex-direction: row;
/* Stacks the icon and text vertically */
padding: 0px;
}

.inner-column {
display: block;
padding: 0px 20px;
}

.inner-column a {
display: block;
}

.detail-text {
flex: 1;
cursor: pointer;
font-size: 12px;
padding-bottom: 5px;
}

.address {
flex: 1;
font-size: 12px;
padding-bottom: 5px;
}

.detail-type {
font-size: 10px;
color: grey;
}

.hr {
width: 1px;
color: rgb(243, 241, 241);
}

.show-location {
padding: 2px;
cursor: pointer;
width: max-content;

}

.location {
color: #27ae60;
font-size: 10px;
}

.label {
font-size: 14px;
color: grey;
padding: 20px 0px;
}

.media-icons {
display: flex;
flex-direction: row;
width: max-content;
}

.social-media-container {
font-size: 16px;
cursor: pointer;
margin: 12px;

}

.vcard-file {
padding: 15px 10px;
background-color: #27ae60;
color: white;
border: 1px solid #27ae60;
border-radius: 7px;
cursor: pointer;
width: 80%;
margin: 0 auto 0;
display: block;
position: sticky;
left: 0;
right: 0;
bottom: 20px;

}

@media screen and (max-width: 700px) {

.upperCard,
.lowerCard {
width: 100%;
box-shadow: none;

}

.row__linked {

border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.upperCard {
padding-top: 20px;
border-radius: 0;
border: 1px solid transparent;
}

.upper {
padding-top: 0;
}

.image {
width: 100px;
height: 100px;
}

.image img {
width: 65px;
}

.upperCard h3 {
font-size: 16px;
}
}

@media screen and (max-width: 390px) {
.vcard-file {
position: sticky;
margin-top: 20px;
bottom: 20px;
}
}